<template>
  <MultiColumnLayout
    v-if="apiProduct.id !== undefined"
    :hide-bar-by-small-resolution="hideBarBySmallResolution"
  >
    <template #sidebar>
      <div class="flex flex-col items-center">
        <ImageAvatar
          :src="image"
          :alt="apiProduct.displayName + 'image'"
          :default-avatar="getRandomRocketByID(apiProduct.id)"
          img-size="h-44 w-44"
        >
        </ImageAvatar>

        <p class="pt-5 truncate w-full text-center text-base font-semibold text-gray-900">
          {{ apiProduct.displayName }}
        </p>
      </div>

      <SidebarCaption>General</SidebarCaption>
      <ApiProductSidebar :api-product-id="apiProductId" />
    </template>

    <slot />
  </MultiColumnLayout>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, watch } from 'vue';
import { getRandomRocketByID, ImageAvatar, SidebarCaption } from '@apiida/vue-components';
import ApiProduct from '../../types/apiProducts/ApiProduct';
import ApiProductService from '../../services/apiProducts/ApiProductService';
import ApiProductSidebar from './ApiProductSidebar.vue';

export default defineComponent({
  components: { ApiProductSidebar, ImageAvatar, SidebarCaption },

  props: {
    apiProductId: {
      type: Number,
      required: true,
    },
    propImage: {
      type: String,
      default: '',
    },
    hideBarBySmallResolution: {
      type: Boolean,
      default: true,
    },
  },

  setup(props) {
    const apiProduct = ref<ApiProduct>({} as ApiProduct);
    const image = ref('');

    async function loadImage(forceReload: boolean = false) {
      await ApiProductService.getImage(props.apiProductId, forceReload, (base64Image: string) => {
        image.value = base64Image;
      });
    }

    onMounted(async () => {
      apiProduct.value = await ApiProductService.get(props.apiProductId);
      await loadImage();
    });

    watch(
      () => props.propImage,
      (newValue) => {
        image.value = newValue;
      },
    );

    return { getRandomRocketByID, apiProduct, image, loadImage };
  },
});
</script>
