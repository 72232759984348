<template>
  <SidebarCaption>General</SidebarCaption>
  <BaseSidebar icon-class="" title-class="" :navigation="general" />

  <SidebarCaption>Documentation</SidebarCaption>
  <BaseSidebar icon-class="" title-class="" :navigation="documentation" />
  <DocPageSidebar :entity-id="apiId" :entity-type="DocEntityType.API" />

  <!--  <span v-if="RoleGuards.isLoggedInUser()">
    <SidebarCaption>Other</SidebarCaption>
    <BaseSidebar icon-class="" title-class="" :navigation="other" />
  </span>-->
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { BaseSidebar, SidebarCaption } from '@apiida/vue-components';
import RoleGuards from '../../routers/guards/RoleGuards';
import DocPageSidebar from '../docPage/DocPageSidebar.vue';
import { DocEntityType } from '@/types/enums/DocEntityType';

const general = [
  {
    name: 'Overview',
    route: 'ApiOverview',
    icon: 'Home',
  },
];

const documentation = [
  {
    name: 'Try it out!',
    route: 'ApiTryItOut',
    icon: 'send-arrow',
  },
];

const other = [
  {
    name: 'Versions',
    route: 'ApiVersions',
    icon: 'squares',
  },
];

export default defineComponent({
  components: {
    DocPageSidebar,
    BaseSidebar,
    SidebarCaption,
  },
  props: {
    apiId: {
      type: Number,
      required: true,
    },
  },
  setup() {
    return {
      DocEntityType,
      RoleGuards,
      general,
      documentation,
      other,
    };
  },
});
</script>
