import { RouteRecordRaw } from 'vue-router';
import RoleGuards from './guards/RoleGuards';

export default [
  {
    path: '/users',
    component: () => import('../views/EmptyPage.vue'),
    beforeEnter: RoleGuards.isAdminOrGlobalAdmin,
    meta: { isEntity: true, BreadcrumbName: 'Users' },
    props: (route) => ({
      ...route.params,
      ...{ id: Number(route.params.id) },
    }),
    children: [
      {
        path: '',
        name: 'Users',
        component: () => import('../views/users/UserList.vue'),
      },
      {
        path: ':id',
        name: 'UserDetails',
        component: () => import('../views/users/UserDetails.vue'),
      },
    ] as RouteRecordRaw[],
  },
] as RouteRecordRaw[];
