<template>
  <PageHeader
    :title="title"
    :show-search="showSearch"
    @update-filter="(filter: string) => emit('updateFilter', filter)"
  >
    <template #Breadcrumbs>
      <BreadcrumbList />
    </template>
    <template #underSearchBar>
      <slot name="underSearchBar" />
    </template>

    <template #buttons>
      <slot name="buttons" />
      <create-account-button
        v-if="!RoleGuards.isLoggedInUser()"
        :open-dialog-button-caption="getBtnText(route)"
      />
    </template>

    <template #tabs>
      <slot name="tabs" />
    </template>
  </PageHeader>
</template>

<script setup lang="ts">
import { PageHeader } from '@apiida/vue-components';
import { RouteLocationNormalizedLoaded, useRoute } from 'vue-router';
import BreadcrumbList from './BreadcrumbList.vue';
import CreateAccountButton from './CreateAccountButton.vue';
import RoleGuards from '../../routers/guards/RoleGuards';

defineProps({
  title: {
    type: String,
    default: '',
  },
  showSearch: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits<{
  updateFilter: [filter: string];
}>();

const route = useRoute();

function getBtnText(userRoute: RouteLocationNormalizedLoaded): string {
  let routePath = '/';
  if (userRoute) routePath = userRoute.path;

  const pathArray = routePath.split('/');
  if (pathArray.includes('apiProducts')) {
    if (pathArray.length === 2) return 'Get access to these API products';
    return 'Get access to this API product';
  }
  if (pathArray.includes('apis')) {
    if (pathArray.length === 2) return 'Get access to these APIs';
    return 'Get access to this API';
  }
  return 'Get access';
}
</script>
