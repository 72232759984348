import { ApiClientHelper } from '@apiida/controlplane-api-helper';
import { AxiosResponse } from 'axios';
import mainStore from '../../stores/MainStore';
import ApiService from '../apis/ApiService';
import SaaSInfo from '../../types/app/SaaSInfo';
import ApiClient from '../ApiClient';
import { TierEnum } from '@/types/enums/TierEnum';
import PortalProperties from '../../types/app/PortalProperties';

export default {
  getMainStore(): any {
    // @ts-ignore
    return mainStore(this.$pinia);
  },

  async loadSaaSInfo(): Promise<SaaSInfo> {
    const response = await ApiClient.get<SaaSInfo>('saasInfo', true);

    const saasInfo: SaaSInfo =
      response?.data ?? ({ isSaaS: false, tier: TierEnum.ENTERPRISE } as SaaSInfo);

    this.getMainStore().SaaSInfo = saasInfo;

    return saasInfo;
  },

  async loadInitialAnonymousInformation(): Promise<void> {
    const promises = [];

    promises.push(ApiService.getAll(true, true));

    await Promise.all(promises);
  },

  async loadProperties(): Promise<PortalProperties> {
    const response = await ApiClient.get<PortalProperties>('devPortals/current/properties', true);

    const portalProperties: PortalProperties = response?.data ?? ({} as PortalProperties);

    if (!portalProperties.colorPrimary) {
      portalProperties.colorPrimary = '#DB6B58';
    }
    if (!portalProperties.colorSecondary) {
      portalProperties.colorSecondary = '#033D58';
    }
    this.getMainStore().setCustomizations(portalProperties);
    return portalProperties;
  },

  async getLogo(fileName: string, callback: any): Promise<boolean> {
    // At the time we need the logo for the login screen, not even the token for the anonymous user is loaded. So we'll get that for this call. But don't save it or anything like that.
    const response = await ApiClient.get<Blob>(
      `/devPortals/current/files/${encodeURIComponent(fileName)}`,
      true,
      {
        responseType: 'blob',
      },
    );

    const success = ApiClientHelper.isSuccess(response as unknown as AxiosResponse);

    if (success && response?.status === 200) {
      const reader = new FileReader();
      reader.onloadend = () => {
        callback(String(reader.result));
      };
      reader.readAsDataURL(response?.data);
    }

    if (success && response?.status === 204) {
      // return no Image found
      callback('');
    }

    return false;
  },
};
