import './index.css';
import '@boomi/exosphere/dist/styles.css';
import '@boomi/exosphere/dist/exo-component-theme.css';
import '@boomi/exosphere';
import { createApp } from 'vue';
import BounceLoader from 'vue-spinner/src/BounceLoader.vue';
import PacmanLoader from 'vue-spinner/src/PacmanLoader.vue';
import Notifications from 'notiwind';
import { createPinia } from 'pinia';
// Import all components from the library
import * as Components from '@apiida/vue-components';
import BeatLoader from 'vue-spinner/src/BeatLoader.vue';
import CustomizedRafikiDynamic from './components/app/CustomizedRafikiDynamic.vue';
import App from './App.vue';
import router from './routers/Router';
import ObsidianPageHeader from './components/app/ObsidianPageHeader.vue';
import config from './config';
import { insertSubdomain } from './helper/TenantHelper';

// Others
const app = createApp(App);
const pinia = createPinia();

app.use(pinia);
app.use(router);
// @ts-ignore
app.use(Notifications);

// @ts-ignore
document.getElementById('favIcon48').href = `${insertSubdomain(config.favIconLink)}`;
// @ts-ignore
document.getElementById('favIconAny').href = `${insertSubdomain(config.favIconLink)}`;
// @ts-ignore
document.getElementById('favIconAppleTouchIcon').href = `${insertSubdomain(config.favIconLink)}`;

// Application wide components
// Loader
app.component('BounceLoader', BounceLoader);
app.component('PacmanLoader', PacmanLoader);
app.component('BeatLoader', BeatLoader);
// PageHeader
app.component('ObsidianPageHeader', ObsidianPageHeader);
// CustomRafiki
app.component('CustomizedRafikiDynamic', CustomizedRafikiDynamic);
// Vue Components
Object.entries(Components).forEach(([name, component]) => {
  if (typeof component === 'object') {
    app.component(name, component);
  }
});

app.mount('#app');
