<template>
  <router-link
    :to="{
      name: `${getEntityRouterName()}`,
      params: { docPageId: docPage.id, entityType: entityType },
    }"
    :class="[
      itemClass,
      'sidebar-link',
      'group flex items-center px-2 py-2 text-sm font-normal rounded-md text-gray-900',
      'hover:font-semibold hover:bg-exo-color-background-selected-hover hover:text-primary-button-text',
      'h-[40px]',
    ]"
    @click="toggleExpand"
  >
    <div class="my-auto">
      <div v-if="docPage.type === DocPageType.FOLDER" class="">
        <BaseIcon
          v-if="docPage.expanded"
          icon-class="mr-9 text-2xl"
          label="Folder"
          name="folder-open-outline"
        />
        <BaseIcon v-else icon-class="mr-9 text-2xl" label="Folder" name="folder-closed-outline" />
      </div>
      <BaseIcon
        v-else-if="docPage.type === DocPageType.MARKDOWN"
        icon-class="mr-9 text-2xl"
        name="document"
        label="Markdown"
      />
      <BaseIcon v-else icon-class="mr-9 text-2xl" label="Rich Text" name="code" />
    </div>

    <div class="grow line-clamp-1">
      {{ docPage.name }}
    </div>

    <div v-if="docPage.children && docPage.children.length > 0" class="mt-5">
      <BaseIcon
        v-if="docPage.expanded"
        icon-class="mr-3 text-xs"
        name="Down arrow"
        label="Close Folder"
      />
      <BaseIcon v-else icon-class="mr-3 text-xs" name="Up arrow" label="Open Folder" />
    </div>
  </router-link>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { BaseIcon } from '@apiida/vue-components';
import DocPage from '../../types/documentation/DocPage';
import { DocEntityType } from '@/types/enums/DocEntityType';
import { DocPageType } from '@/types/documentation/DocPageType';

export default defineComponent({
  components: { BaseIcon },
  props: {
    docPage: {
      type: Object as PropType<DocPage>,
      required: true,
    },
    itemClass: {
      type: String,
      default: '',
    },
    entityType: {
      type: String as PropType<DocEntityType>,
      default: DocEntityType.API,
    },
  },
  emits: ['toggle-expand'],
  setup(props, { emit }) {
    function getEntityRouterName() {
      if (props.entityType === DocEntityType.API) return 'ApiDocumentation';
      return 'ApiProductDocumentation';
    }

    function toggleExpand() {
      if (props.docPage.children && props.docPage.children.length > 0) {
        emit('toggle-expand');
      }
    }

    return { toggleExpand, getEntityRouterName };
  },
  computed: {
    DocPageType() {
      return DocPageType;
    },
  },
});
</script>
