import { ApiClientHelper } from '@apiida/controlplane-api-helper';
import ApiClient from '../ApiClient';
import CmsBlock from '../../types/app/cms/CmsBlock';
import CmsPage from '../../types/app/cms/CmsPage';
import CmsMenuItem from '../../types/app/cms/CmsMenuItem';

class CmsService {
  // region MENU
  async getMenu(): Promise<CmsMenuItem[]> {
    const response = await ApiClient.get<CmsMenuItem[]>(`/devPortals/current/menuItems`);
    return response?.data ?? [];
  }

  // endregion

  // region PAGE

  async getPage(name: string): Promise<CmsPage> {
    const response = await ApiClient.get<CmsPage>(
      `/devPortals/current/pages/${encodeURIComponent(name)}`,
    );
    return response?.data ?? ({} as CmsPage);
  }

  // endregion

  // region BLOCKS
  async getBlock(name: string): Promise<CmsBlock> {
    const response = await ApiClient.get<CmsBlock>(
      `/devPortals/current/blocks/${encodeURIComponent(name)}`,
    );
    return response?.data ?? ({} as CmsBlock);
  }

  async updateBlock(cmsBlock: CmsBlock): Promise<boolean> {
    const response = await ApiClient.put(
      `/devPortals/current/blocks/${encodeURIComponent(cmsBlock.name)}`,
      cmsBlock,
    );
    return ApiClientHelper.isSuccess(response);
  }

  async getAllBlocks(): Promise<CmsBlock[]> {
    const response = await ApiClient.get<CmsBlock[]>(`/devPortals/current/blocks`);
    return response?.data ?? [];
  }

  async updatePage(cmsPage: CmsPage): Promise<boolean> {
    const response = await ApiClient.put(
      `/devPortals/current/pages/${encodeURIComponent(cmsPage.name)}`,
      cmsPage,
    );
    return ApiClientHelper.isSuccess(response);
  }

  async createBlock(block: CmsBlock): Promise<boolean> {
    const response = await ApiClient.post(`/devPortals/current/blocks`, block);
    return ApiClientHelper.isSuccess(response);
  }

  async deleteBlock(name: string): Promise<boolean> {
    const response = await ApiClient.delete(
      `/devPortals/current/blocks/${encodeURIComponent(name)}`,
    );
    return ApiClientHelper.isSuccess(response);
  }

  // endregion
}

export default new CmsService();
