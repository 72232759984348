/* eslint-disable */
// pSBC - Shade Blend Convert - Version 4.1 - 01/7/2021
// https://github.com/PimpTrizkit/PJs/blob/master/pSBC.js
// https://github.com/PimpTrizkit/PJs/wiki/12.-Shade,-Blend-and-Convert-a-Web-Color-(pSBC.js)

const pSBC = (percent: any, colorFrom: any, colorTo: any, l: any) => {
  let r;
  let g;
  let b;
  let P;
  let f;
  let t;
  let h;
  const m = Math.round;
  let a: number = typeof colorTo === 'string' ? 1 : 0;
  if (
    typeof percent !== 'number' ||
    percent < -1 ||
    percent > 1 ||
    typeof colorFrom !== 'string' ||
    (colorFrom[0] !== 'r' && colorFrom[0] !== '#') ||
    (colorTo && !a)
  )
    return null;
  (h = colorFrom.length > 9),
    (h = a ? (colorTo.length > 9 ? true : colorTo === 'c' ? !h : false) : h),
    (f = pSBC.pSBCr(colorFrom)),
    (P = percent < 0),
    (t =
      colorTo && colorTo !== 'c'
        ? pSBC.pSBCr(colorTo)
        : P
          ? { r: 0, g: 0, b: 0, a: -1 }
          : { r: 255, g: 255, b: 255, a: -1 }),
    (percent = P ? percent * -1 : percent),
    (P = 1 - percent);
  if (!f || !t) return null;
  if (l)
    (r = m(P * f.r + percent * t.r)),
      (g = m(P * f.g + percent * t.g)),
      (b = m(P * f.b + percent * t.b));
  else
    (r = m((P * f.r ** 2 + percent * t.r ** 2) ** 0.5)),
      (g = m((P * f.g ** 2 + percent * t.g ** 2) ** 0.5)),
      (b = m((P * f.b ** 2 + percent * t.b ** 2) ** 0.5));
  (a = f.a),
    (t = t.a),
    (f = a >= 0 || t >= 0),
    (a = f ? (a < 0 ? t : t < 0 ? a : a * P + t * percent) : 0);
  if (h) return `rgb${f ? 'a(' : '('}${r},${g},${b}${f ? `,${m(a * 1000) / 1000}` : ''})`;
  return `#${(4294967296 + r * 16777216 + g * 65536 + b * 256 + (f ? m(a * 255) : 0))
    .toString(16)
    .slice(1, f ? undefined : -2)}`;
};

pSBC.pSBCr = (color: any): { r: any; g: any; b: any; a: any } | null => {
  const i = parseInt;
  let n = color.length;
  const x: { r: any; g: any; b: any; a: any } = {
    r: undefined,
    g: undefined,
    b: undefined,
    a: undefined,
  };
  if (n > 9) {
    const [r, g, b, a] = (color = color.split(','));
    n = color.length;
    if (n < 3 || n > 4) return null;
    (x.r = i(r[3] === 'a' ? r.slice(5) : r.slice(4))),
      (x.g = i(g)),
      (x.b = i(b)),
      (x.a = a ? parseFloat(a) : -1);
  } else {
    if (n === 8 || n === 6 || n < 4) return null;
    if (n < 6)
      color = `#${color[1]}${color[1]}${color[2]}${color[2]}${color[3]}${color[3]}${
        n > 4 ? color[4] + color[4] : ''
      }`;
    color = i(color.slice(1), 16);
    if (n === 9 || n === 5)
      (x.r = (color >> 24) & 255),
        (x.g = (color >> 16) & 255),
        (x.b = (color >> 8) & 255),
        (x.a = Math.round((color & 255) / 0.255) / 1000);
    else (x.r = color >> 16), (x.g = (color >> 8) & 255), (x.b = color & 255), (x.a = -1);
  }
  return x;
};

export default function shadeBlendConvertColor(factor: number, color: string): string {
  const result = pSBC(factor, color, undefined, undefined);

  return result ?? '';
}
