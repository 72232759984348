<template>
  <div
    v-if="jobs.length > 0"
    class="flex flex-col justify-center items-center"
    @click="jobsView = true"
  >
    <info-badge :class="lvl1BarOpen ? 'ml-12' : 'ml-auto'">{{ jobs.length }}</info-badge>
    <bounce-loader :loading="true" :color="store.customizations.colorPrimary" />
  </div>

  <BaseDialog v-if="jobsView" id="jobViewer" title="Job Overview" @close-dialog="jobsView = false">
    <div v-for="job in jobs" :key="job.id">
      <div class="pt-3">
        <h1 class="ml-6 text-lg font-semibold inline-flex">
          {{ job.jobName }}
        </h1>
        <h2 class="ml-6 text-bas2 inline-flex">{{ job.jobStatus }}</h2>
        <h3 class="ml-6 text-sm inline-flex">{{ job.progressMessage }}</h3>
      </div>
      <div class="flex flex-wrap p-4">
        <ex-loader variant="general-loader" size="default" />
      </div>
    </div>

    <CustomizedRafikiDynamic
      :show="jobs.length === 0"
      :image-width="40"
      text="Looks like there are no active jobs at the moment."
    />
  </BaseDialog>
</template>

<script lang="ts">
import { defineComponent, onMounted, onUnmounted, ref } from 'vue';

import { BaseDialog, InfoBadge } from '@apiida/vue-components';
import { Job, WebSocketClient } from '@apiida/controlplane-api-helper';
import { insertSubdomain } from '@/helper/TenantHelper';
import config from '../../config';
import mainStore from '../../stores/MainStore';

export default defineComponent({
  components: { BaseDialog, InfoBadge },
  props: {
    lvl1BarOpen: {
      type: Boolean,
      required: true,
    },
  },
  setup() {
    const jobs = ref<Job[]>([]);
    const jobsView = ref<boolean>(false);
    const store = mainStore();
    const token = localStorage.getItem('accessToken')?.replace('Bearer ', '') ?? '';
    const websocketUrl = insertSubdomain(`${config.backendWs}/jobsWebsocket?token=${token}`);

    const webSocketClient = new WebSocketClient(websocketUrl);

    onMounted(async () => {
      webSocketClient.connect((jobsArray: Job[]) => {
        jobs.value = jobsArray;
      });
    });

    onUnmounted(() => {
      try {
        webSocketClient.disconnect();
      } catch (e) {
        console.log('webSocketClient.disconnect() failed');
      }
    });

    return { jobs, jobsView, store };
  },
});
</script>
