<template>
  <BaseSidebar icon-class="" title-class="" :navigation="items" />
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from 'vue';
import { BaseSidebar } from '@apiida/vue-components';
import CustomApplicationFieldsService from '../../services/configuration/CustomApplicationFieldsService';

const baseNavigation = [
  {
    name: 'Overview',
    route: 'ApplicationOverview',
    icon: 'Home',
  },
  {
    name: 'Subscriptions',
    route: 'ApplicationSubscriptions',
    icon: 'play',
  },
];

const settings = {
  name: 'Settings',
  route: 'ApplicationSettings',
  icon: 'Settings',
};

const customFields = {
  name: 'Custom Fields',
  route: 'ApplicationCustomFields',
  icon: 'Table',
};

export default defineComponent({
  components: { BaseSidebar },
  setup() {
    const items = ref<
      {
        name: string;
        route: string;
        icon: any;
      }[]
    >([]);
    onMounted(async () => {
      items.value.push(...baseNavigation);

      const tmpCustomFields = await CustomApplicationFieldsService.getAll();
      if (tmpCustomFields.length > 0) {
        items.value.push({ ...customFields });
      }

      items.value.push({ ...settings });
    });
    return {
      items,
    };
  },
});
</script>
