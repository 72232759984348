import {
  ApiClientHelper,
  DeploymentStatus,
  ExtraParamPageable,
  Pageable,
  SimplePage,
  TableHeaderPageable,
} from '@apiida/controlplane-api-helper';
import Application from '../../types/applications/Application';
import applicationStore from '../../stores/applicationStore';
import mainStore from '../../stores/MainStore';
import PaginationService from '../PaginationService';
import ApiClient from '../ApiClient';
import Subscription from '../../types/applications/Subscription';
import ApplicationCustomFieldContainer from '../../types/applications/ApplicationCustomFieldContainer';

class ApplicationService extends PaginationService<Application> {
  constructor() {
    super('/applications', 'Application');
  }

  getMainStore() {
    // @ts-ignore
    return mainStore(this.$pinia);
  }

  async getSubscriptions(
    applicationId: number,
    silent = false,
    pageable: Pageable = {
      page: 1,
      size: 99999,
      sort: [],
    } as Pageable,
    header: Array<TableHeaderPageable> = [],
    extraParams: Array<ExtraParamPageable> = [],
  ): Promise<SimplePage<Subscription>> {
    return this.getAllPagination(
      pageable,
      header,
      extraParams,
      silent,
      `${this.url}/${applicationId}/subscriptions`,
    ) as unknown as SimplePage<Subscription>;
  }

  async update(data2: Application | undefined, silent?: boolean): Promise<boolean> {
    const result = super.update(data2, silent);

    result.then((success: boolean) => {
      if (success && data2) {
        const updatedApp = this.storeGetEntity(data2.id);
        if (updatedApp && updatedApp.deploymentStatus === DeploymentStatus.DEPLOYMENT_REQUIRED) {
          this.deployAsync(updatedApp.id, `for ${updatedApp.displayName}`, silent);
        }
      }
    });

    return result;
  }

  storeFill(entitys: Application[]): void {
    this.getStore().applications = entitys;
  }

  storeGetAllEntitys(): Application[] {
    return this.getStore().applications;
  }

  getStore(): any {
    return applicationStore();
  }

  async getCustomFields<T = ApplicationCustomFieldContainer>(
    applicationId: number,
    silent: boolean = false,
  ): Promise<T> {
    const response = await ApiClient.get<T>(`${this.url}/${applicationId}/customFields`, silent);
    return response?.data ?? ({} as T);
  }

  async updateCustomFields<T = ApplicationCustomFieldContainer>(
    applicationId: number,
    data: T,
    silent: boolean = false,
  ): Promise<boolean> {
    const response = await ApiClient.put<T>(
      `${this.url}/${applicationId}/customFields`,
      data,
      silent,
    );
    return ApiClientHelper.isSuccess(response);
  }
}

export default new ApplicationService();
