<template>
  <BaseSidebar icon-class="" title-class="" :navigation="navigation" />

  <SidebarCaption>Documentation</SidebarCaption>
  <div class="mt-8">
    <DocPageSidebar :entity-id="apiProductId" :entity-type="DocEntityType.API_PRODUCT" />
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from 'vue';
import { BaseSidebar, SidebarCaption } from '@apiida/vue-components';
import DocPageSidebar from '../docPage/DocPageSidebar.vue';
import { DocEntityType } from '@/types/enums/DocEntityType';

export default defineComponent({
  components: { BaseSidebar, SidebarCaption, DocPageSidebar },
  props: {
    apiProductId: {
      type: Number,
      required: true,
    },
  },
  setup() {
    const navigation = ref<
      {
        name: string;
        route: string;
        icon: any;
        default: boolean;
      }[]
    >([]);
    onMounted(async () => {
      navigation.value.push({
        name: 'Overview',
        route: 'ApiProductOverview',
        icon: 'Home',
        default: true,
      });
      navigation.value.push({
        name: 'APIs',
        route: 'ApiProductAPIs',
        icon: 'Types',
        default: false,
      });

      navigation.value.push({
        name: 'Plans',
        route: 'ApiProductPlans',
        icon: 'Paste',
        default: false,
      });
    });
    return {
      DocEntityType,
      navigation,
    };
  },
});
</script>
