<template>
  <RafikiDynamic
    :image-width="imageWidth"
    :show="show"
    :text="text"
    :svg-name="svgName"
    :color="
      mainStore().customizations.colorPrimary.length > 0
        ? mainStore().customizations.colorPrimary
        : '#DB6B58'
    "
  />
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { RafikiDynamic } from '@apiida/vue-components';
import mainStore from '../../stores/MainStore';

export default defineComponent({
  components: { RafikiDynamic },
  props: {
    imageWidth: {
      type: Number,
      default: 40,
    },
    show: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: 'No Data',
    },
    svgName: {
      type: String as PropType<
        'NoData' | 'Success' | 'AtWork' | 'AssetSelection' | 'Astronaut' | 'Support'
      >,
      default: 'NoData',
    },
  },
  methods: { mainStore },
});
</script>
